@use "../../../variables.scss" as *;

.oauth-button-container {
  text-align: center;

  > p {
    margin: 16px 0;
  }

  .dx-button {
    margin-top: 8px;
  }
}

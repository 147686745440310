@use "../../../variables.scss" as *;

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05), 0 0 4px rgba(0, 0, 0, 0.15);
  padding: 10px 0;
  background-color: var(--base-bg);

  .header-title {
    color: var(--accent-color);
  }

  .dx-toolbar.header-toolbar {
    padding-right: var(--content-padding);

    .global-search-box {
      padding-right: 11px;
    }

    .messages {
      position: relative;

      .dx-badge {
        display: block;
        position: absolute;
        background-color: red;
        color: white;
        right: -10%;
        top: -10%;
        font-size: 12px;
        padding: 0 4px;
      }
    }

    .dx-toolbar-item.menu-button {
      width: var(--side-panel-min-width);
      text-align: center;
      padding: 0;

      .dx-icon {
        color: var(--accent-color);
      }
    }
  }
}

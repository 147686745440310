@use "../../../variables.scss" as *;

.notes {
  .input-notes {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }

  .note {
    background-color: var(--base-bg);
    padding: 10px;
    margin-bottom: 10px;

    .note-title {
      @include message-title();
    }

    .note-text {
      @include message-text();
    }
  }
}

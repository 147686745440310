.card {
  &.sales-by-state-map {
    .card-contents {
      height: 400px;
    }
  }

  &.sales-range {
    .card-contents {
      height: auto;
    }
  }
}

.sales-pie,
.sales-bars {
  display: inline-block;
  height: 100%;
}

.sales-pie {
  width: 400px;
}

.sales-bars {
  width: calc(100% - 600px);
}

.sales-filter {
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  .sales-pie,
  .sales-bars {
    display: block;
  }

  .sales-pie {
    width: 320px;
  }

  .sales-bars {
    width: 320px;
  }
}

.cards {
  display: grid;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
  grid-template-columns: repeat(2, calc(50% - 10px));

  &.wide {
    margin-right: 0;
    grid-template-columns: repeat(1, 100%);
  }
}

@media only screen and (max-width: 900px) {
  .cards {
    grid-template-columns: repeat(1, 100%);
  }
}

@use "../../variables.scss" as *;

.view-wrapper-task-list {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;

  .dx-toolbar.toolbar-common {
    padding: var(--content-padding);

    .toolbar-header {
      @include header();
    }
  }

  .planning-grid,
  .kanban,
  .gantt {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    max-height: calc(100% - var(--toolbar-height) - var(--content-padding) * 2);
  }

  .planning-grid {
    & > .dx-datagrid {
      min-height: 100%;
    }
  }

  .gantt {
    padding: 0 var(--content-padding) var(--content-padding);
  }

  .kanban {
    padding: 0 0 var(--content-padding) calc(var(--content-padding) / 2);
  }
}

@use "../../../variables.scss" as *;
@use "sass:math";

#right-side-panel {
  --side-panel-width: calc(var(--calendar-width) + var(--content-padding));
}

.screen-x-small #side-panel {
  --side-panel-width: 100vw;
}

.right-side-panel {
  display: none;
  overflow: auto;
  &.open {
    flex: 0 1 var(--side-panel-width);
    display: block;
    border: solid 1px var(--border-color);
    border-radius: 8px;
  }

  &.overlap {
    position: absolute;
    padding-top: var(--content-padding);
    top: 0;
    bottom: 0;
    z-index: 5;
    background: var(--base-bg);

    &.x-small {
      padding-top: 0;
    }

    &.side-right {
      right: calc(-1 * var(--side-panel-width));
      transition: right 400ms;
    }

    &.open {
      right: 0;
      transition: right 400ms ease;
      border: 2px var(--border-color);
      border-radius: 0;
      width: var(--side-panel-width);
      z-index: 7;
      box-shadow: 0 0 8px var(--border-color);
    }
  }
}

.right-panel-open-button.dx-button {
  margin-top: var(--theme-padding);
  height: var(--button-height);
  z-index: 6;

  .dx-icon {
    color: var(--accent-color);
  }

  &.hidden {
    display: none;
  }
}

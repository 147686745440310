@use "../../../variables.scss" as *;

.reset-password-form {
  .submit-button {
    margin-top: 18px;
  }

  .login-link {
    color: var(--accent-color);
    font-size: 12px;
    text-align: center;
    margin-top: 6px;
  }
}

@use "../../../variables.scss" as *;
@use "sass:math";

#left-side-panel {
  --left-side-panel-width: 320px;
  --transition: 400ms;
}

.left-side-panel {
  position: relative;
  border: 1px solid transparent;

  .side-panel-container {
    display: flex;
    height: 100%;

    .side-panel-content-wrapper {
      height: 100%;

      .side-panel-content {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .side-panel-content-wrapper {
    width: 0;
    transition: width var(--transition);
    height: calc(100vh - 60px - var(--theme-padding));
    overflow: hidden;
    white-space: nowrap;

    .dx-scrollview {
      min-width: var(--left-side-panel-width);
    }
  }

  &.open {
    .side-panel-content-wrapper {
      width: var(--left-side-panel-width);
      padding-left: 0;
    }
  }

  &.overlapping {
    .button-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: calc(var(--button-height) + var(--theme-padding) * 2);
    }

    .side-panel-content-wrapper {
      position: absolute;
      top: calc(-1 * var(--theme-padding));
      left: var(--button-height);
      height: calc(100vh - 60px);
      padding-top: var(--theme-padding);
      background-color: var(--base-bg);
      z-index: 3;
      box-shadow: 8px 0 16px -8px var(--border-color);

      &.open {
        padding-right: var(--content-padding);
      }
    }
  }
}
